import "@mittwald/flow-pro-icons";
import { createElement } from "react";
import { createRoot } from "react-dom/client";
import { WhitelabelWrapper } from "./whitelabel/WhitelabelWrapper";

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(createElement(WhitelabelWrapper));
}
