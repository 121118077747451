import { createContext, useContext } from "react";

export interface WhitelabelContext {
  whitelabelCssUrl?: string;
  isWhitelabel: boolean;
}

export const whitelabelContext = createContext<WhitelabelContext>({
  isWhitelabel: false,
});

export const useWhitelabelContext = (): WhitelabelContext =>
  useContext(whitelabelContext);
