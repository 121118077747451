import z from "zod";
import camelcase from "camelcase";

const ConfigSchema = z.object({
  StatusMonitorApiUri: z.string(),
  StatusMonitorPageUri: z.string(),
  AppTitle: z.string(),
  AppApiUri: z.string(),
  AppDescription: z.string().optional(),
  AppVersion: z.string(),
  DevWhitelabelDomain: z.string().optional(),
  TypoBaseUrl: z.string().default(""),
  PhpEltsUri: z.string(),
});

export type Config = z.infer<typeof ConfigSchema>;

const normalizeConfigKey = (key: string): string =>
  camelcase(key.replace(/^VITE_/, ""), { pascalCase: true });

const normalizedConfig = Object.fromEntries(
  Object.entries(import.meta.env).map(([key, value]) => [
    normalizeConfigKey(key),
    value,
  ]),
);

export const config = ConfigSchema.parse(normalizedConfig);
