import { setProperty } from "dot-prop";
import React, { FC, PropsWithChildren } from "react";
import { color } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import { useWhitelabelContext } from "./WhitelabelContext";
import { createGlobalStyle } from "styled-components";
import { darken, lighten, setLightness } from "polished";
import { useHttpResponse } from "../hooks/useHttpResponse";

type Colors = Record<string, string>;

const DisableFallbackLogoStyle = createGlobalStyle`
.logo-company > img {
  display: none;
}
`;

const parseColorVariables = (css: string): Colors =>
  Object.fromEntries(
    Array.from(css.matchAll(/--color-((.*): (.*?));/gm)).map((entry) => [
      entry[2],
      entry[3],
    ]),
  );

const setCol = (path: string, newColor: string): void => {
  setProperty(color, path, newColor);
};

const setCssVariables = (css: string): void => {
  const colors = parseColorVariables(css);

  // status info
  setCol("status.info.backgroundLighter", lighten(0.5, colors["info"]));
  setCol("status.info.backgroundLight", lighten(0.4, colors["info"]));
  setCol("status.info.backgroundDark", lighten(0.3, colors["info"]));
  setCol("status.info.lighter", lighten(0.2, colors["info"]));
  setCol("status.info.light", lighten(0.1, colors["info"]));
  setCol("status.info.default", colors["info"]);
  setCol("status.info.dark", darken(0.1, colors["info"]));
  setCol("status.info.darker", darken(0.2, colors["info"]));

  // status success
  setCol("status.success.backgroundLighter", lighten(0.5, colors["success"]));
  setCol("status.success.backgroundLight", lighten(0.4, colors["success"]));
  setCol("status.success.backgroundDark", lighten(0.3, colors["success"]));
  setCol("status.success.lighter", lighten(0.2, colors["success"]));
  setCol("status.success.light", lighten(0.1, colors["success"]));
  setCol("status.success.default", colors["success"]);
  setCol("status.success.dark", darken(0.1, colors["success"]));
  setCol("status.success.darker", darken(0.2, colors["success"]));

  // status warning
  setCol(
    "status.warning.backgroundLighter",
    setLightness(0.85, colors["warning"]),
  );
  setCol(
    "status.warning.backgroundLight",
    setLightness(0.8, colors["warning"]),
  );
  setCol(
    "status.warning.backgroundDark",
    setLightness(0.75, colors["warning"]),
  );
  setCol("status.warning.lighter", setLightness(0.45, colors["warning"]));
  setCol("status.warning.light", setLightness(0.4, colors["warning"]));
  setCol("status.warning.default", setLightness(0.35, colors["warning"]));
  setCol("status.warning.dark", setLightness(0.3, colors["warning"]));
  setCol("status.warning.darker", setLightness(0.25, colors["warning"]));

  // status error
  setCol("status.error.backgroundLighter", lighten(0.5, colors["danger"]));
  setCol("status.error.backgroundLight", lighten(0.4, colors["danger"]));
  setCol("status.error.backgroundDark", lighten(0.3, colors["danger"]));
  setCol("status.error.lighter", lighten(0.2, colors["danger"]));
  setCol("status.error.light", lighten(0.1, colors["danger"]));
  setCol("status.error.default", colors["danger"]);
  setCol("status.error.dark", darken(0.1, colors["danger"]));
  setCol("status.error.darker", darken(0.2, colors["danger"]));

  // brand primary
  setCol("brand.primary.lighter", colors["primary-50"]);
  setCol("brand.primary.light", colors["primary-75"]);
  setCol("brand.primary.default", colors["primary-100"]);
  setCol("brand.primary.dark", darken(0.1, colors["primary-100"]));
  setCol("brand.primary.darker", colors["primary-300"]);

  // brand secondary
  setCol(
    "brand.secondary.backgroundLighter",
    lighten(0.2, colors["action-50"]),
  );
  setCol("brand.secondary.backgroundLight", lighten(0.1, colors["action-50"]));
  setCol("brand.secondary.backgroundDark", colors["action-50"]);
  setCol("brand.secondary.light", colors["action-75"]);
  setCol("brand.secondary.default", colors["action-100"]);
  setCol("brand.secondary.dark", colors["action-200"]);
  setCol("brand.secondary.darker", darken(0.1, colors["action-200"]));

  // brand highlight
  setCol(
    "brand.highlight.backgroundLight",
    lighten(0.2, colors["complementary-50"]),
  );
  setCol(
    "brand.highlight.backgroundDark",
    lighten(0.1, colors["complementary-50"]),
  );
  setCol("brand.highlight.light", colors["complementary-50"]);
  setCol("brand.highlight.default", colors["complementary-100"]);
  setCol("brand.highlight.dark", darken(0.1, colors["complementary-100"]));
  setCol("brand.highlight.darker", darken(0.2, colors["complementary-100"]));
  setCol("background.darker", darken(0.1, colors["secondary-200"]));

  // font link
  setCol("font.link.default", setLightness(0.4, colors["primary-100"]));
  setCol("font.link.hover", setLightness(0.2, colors["primary-100"]));
  setCol("font.link.active", colors["primary-300"]);
  setCol("font.link.disabled", colors["primary-50"]);
  setCol("font.link.inverse.default", colors["inverse"]);

  // font destructive link
  setCol("font.destructiveLink.default", colors["danger"]);
  setCol("font.destructiveLink.hover", darken(0.1, colors["danger"]));
  setCol("font.destructiveLink.active", darken(0.2, colors["danger"]));
  setCol("font.destructiveLink.disabled", lighten(0.2, colors["danger"]));
  setCol("font.destructiveLink.inverse.default", colors["inverse"]);

  // font button link
  setCol("font.button.link.default", colors["primary-100"]);
  setCol("font.button.link.hover", darken(0.1, colors["primary-100"]));
  setCol("font.button.link.active", darken(0.1, colors["primary-100"]));
  setCol("font.button.link.disabled", colors["primary-50"]);

  // font button cancel
  setCol("font.button.cancel.default", colors["primary-100"]);
  setCol("font.button.cancel.hover", darken(0.1, colors["primary-100"]));
  setCol("font.button.cancel.active", darken(0.2, colors["primary-100"]));
  setCol("font.button.cancel.disabled", colors["primary-50"]);

  // font button continue
  setCol("font.button.continue.default", colors["inverse"]);
  setCol("font.button.continue.hover", colors["inverse"]);
  setCol("font.button.continue.active", colors["inverse"]);
  setCol("font.button.continue.disabled", colors["inverse"]);

  // font button complete
  setCol("font.button.complete.default", colors["inverse"]);
  setCol("font.button.complete.hover", colors["inverse"]);
  setCol("font.button.complete.active", colors["inverse"]);
  setCol("font.button.complete.disabled", colors["inverse"]);

  // font button destructive
  setCol("font.button.destructive.default", colors["inverse"]);
  setCol("font.button.destructive.hover", colors["inverse"]);
  setCol("font.button.destructive.active", colors["inverse"]);
  setCol("font.button.destructive.disabled", colors["inverse"]);

  // font headline
  setCol("font.headline.default", colors["primary-100"]);
  setCol("font.headline.inverse", colors["inverse"]);

  // font text
  setCol("font.text.default", colors["text-100"]);
  setCol("font.text.disabled", colors["text-50"]);
  setCol("font.text.error", colors["danger"]);
  setCol("font.text.inverse.default", colors["inverse"]);
  setCol("font.label.default", colors["text-75"]);

  // font input
  setCol("font.input.default", colors["text-100"]);
  setCol("font.input.disabled", colors["text-50"]);
  setCol("font.input.placeholder", colors["text-50"]);
  setCol("font.input.error", colors["danger"]);

  // background
  setCol("background.light", colors["background"]);
  setCol("background.default", colors["secondary-100"]);
  setCol("background.dark", colors["secondary-200"]);
  setCol("background.disabled", colors["disabled-100"]);

  // background button cancel
  setCol("background.button.cancel.default", colors["disabled-100"]);
  setCol("background.button.cancel.hover", colors["disabled-200"]);
  setCol("background.button.cancel.active", colors["disabled-200"]);
  setCol("background.button.cancel.disabled", colors["secondary-100"]);

  // background button complete
  setCol("background.button.complete.default", colors["action-100"]);
  setCol("background.button.complete.hover", colors["action-200"]);
  setCol(
    "background.button.complete.active",
    darken(0.1, colors["action-200"]),
  );
  setCol("background.button.complete.disabled", colors["action-75"]);

  // background button continue
  setCol("background.button.continue.default", colors["primary-100"]);
  setCol(
    "background.button.continue.hover",
    darken(0.1, colors["primary-100"]),
  );
  setCol("background.button.continue.active", colors["primary-300"]);
  setCol("background.button.continue.disabled", colors["primary-50"]);

  // background button destructive
  setCol("background.button.destructive.default", colors["danger"]);
  setCol("background.button.destructive.hover", darken(0.1, colors["danger"]));
  setCol("background.button.destructive.active", darken(0.2, colors["danger"]));
  setCol(
    "background.button.destructive.disabled",
    lighten(0.2, colors["danger"]),
  );

  // background selectable box
  setCol("background.selectableBox.default", lighten(0.15, colors["inverse"]));
  setCol(
    "background.selectableBox.hovered",
    setLightness(0.95, colors["primary-50"]),
  );
  setCol(
    "background.selectableBox.selected",
    setLightness(0.9, colors["primary-50"]),
  );
  setCol("background.selectableBox.disabled", colors["disabled-100"]);
  setCol(
    "background.selectableBox.selectedDisabled",
    darken(0.1, colors["disabled-100"]),
  );

  // background gradient
  setCol("background.gradient.to", colors["background"]);
  setCol("background.gradient.from", colors["secondary-200"]);

  // background input
  setCol("background.input.default", setLightness(0.95, colors["text-25"]));
  setCol("background.input.hovered", setLightness(0.9, colors["text-25"]));
  setCol("background.input.focused", setLightness(0.9, colors["text-25"]));
  setCol("background.input.disabled", setLightness(0.85, colors["text-25"]));
  setCol("background.input.error", lighten(0.2, colors["danger"]));
  setCol("background.input.autofill", setLightness(0.85, colors["text-25"]));

  // border input
  setCol("border.input.default", colors["border"]);
  setCol("border.input.hovered", colors["border"]);
  setCol("border.input.focused", colors["primary-100"]);
  setCol("border.input.disabled", colors["disabled-100"]);
  setCol("border.input.error", colors["danger"]);

  // border horizontal ruler
  setCol("border.horizontalRuler.default", colors["border"]);

  // border row
  setCol("border.row.default", colors["border"]);
  setCol("border.row.selected", colors["primary-100"]);

  // border selectable box
  setCol("border.selectableBox.default", colors["border"]);
  setCol("border.selectableBox.selected", colors["primary-100"]);
};

export const WhitelabelGlobalStyle: FC<PropsWithChildren> = (props) => {
  const { whitelabelCssUrl, isWhitelabel } = useWhitelabelContext();

  const css = useHttpResponse<string>(
    isWhitelabel ? whitelabelCssUrl : undefined,
  );

  const hasWhitelabelLogo = css?.includes(".logo-company");

  if (css) {
    try {
      setCssVariables(css);
    } catch (err) {
      console.warn("Could not set CSS variables. CSS possibly invalid.", err);
    }
  }

  const CompanyStyle = createGlobalStyle`${css}`;

  return (
    <>
      <CompanyStyle />
      {hasWhitelabelLogo && <DisableFallbackLogoStyle />}
      {props.children}
    </>
  );
};
