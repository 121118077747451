import React, { FC, PropsWithChildren } from "react";
import { whitelabelContext } from "./WhitelabelContext";
import { config } from "../config";

export const WhitelabelContextProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const query = new URLSearchParams(window.location.search);
  const domainFromQuery = query.get("test-whitelabel-domain");

  const whitelabelDomain =
    domainFromQuery ?? config.DevWhitelabelDomain ?? document.location.hostname;

  const isMittwaldDomain = /.+\.mittwald\.(de|it|systems)$/.test(
    whitelabelDomain,
  );

  const isLocalhost = whitelabelDomain === "localhost";

  const whitelabelBaseUrl =
    (domainFromQuery ? `https://${domainFromQuery}` : undefined) ??
    config.TypoBaseUrl;

  const whitelabelCssUrl = `${whitelabelBaseUrl}/rest/v2/design/${whitelabelDomain}`;

  return (
    <whitelabelContext.Provider
      value={{
        whitelabelCssUrl,
        isWhitelabel: !isLocalhost && !isMittwaldDomain,
      }}
    >
      {children}
    </whitelabelContext.Provider>
  );
};
