import "@mittwald/flow-pro-icons";
import "regenerator-runtime";
import React, { FC, lazy } from "react";
import { WhitelabelContextProvider } from "./WhitelabelContextProvider";
import { WhitelabelGlobalStyle } from "./WhitelabelGlobalStyle";

const App = lazy(() => import("../App"));

export const WhitelabelWrapper: FC = () => {
  return (
    <WhitelabelContextProvider>
      <WhitelabelGlobalStyle>
        <App />
      </WhitelabelGlobalStyle>
    </WhitelabelContextProvider>
  );
};
